<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-container>
    <v-card>
      <v-card-title class="text-h5">
        <h2>Assign JCOM Table</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Assign JCOM Table for the selected Region</h4></v-card-subtitle
      >

      <v-footer :fixed="true" :absolute="false" :padless="true">
        <v-card
          flat
          tile
          width="100%"
          color="#8950FC"
          v-if="tableData1.length > 0"
        >
          <v-container>
            <!-- <v-divider></v-divider> -->
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" lg="12" md="12">
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-footer>

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" sm="12" lg="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6><span class="text-danger">*</span> Zone</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Table Type<small> (optional)</small></h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="TableTypeOptions"
                  :rules="TableTypeRules"
                  v-model="TableType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
                <v-btn
                  @click.prevent="resetForm"
                  color="#00a4ef"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-form
            ref="form2"
            v-model="valid2"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3>{{ tableData1.length }} records found.</h3>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                    Delete
                  </v-btn> -->
                <p></p>
                <json-excel
                  v-if="
                    tableData1.length > 0 &&
                    tableOptions1.JsonDownloadButtonFlag == true
                  "
                  :name="tableOptions1.ExcelFileName"
                  :data="tableData1"
                  :fields="tableOptions1.JsonExcelFields"
                >
                  <v-btn color="info" class="mr-4"> Download </v-btn>
                </json-excel>
              </v-col>
              <p></p>
            </v-row>
            <v-container class="py-0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                    @click.prevent="generateReportExcel"
                    :loading="ExcelLoadingFlag"
                    target="_blank"
                    color="#ff7f50"
                  >
                    Generate Report
                  </v-btn>
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    v-if="ExcelFileName != '' && ExcelFileFlag == true"
                    :href="ExcelFileName"
                    target="_blank"
                    color="#2fb65a"
                  >
                    <v-icon dark> mdi-download </v-icon> Download Report
                  </v-btn>
                </v-col>
              </v-row>
              <p></p>
            </v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search records here"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  loading-text="Loading... Please wait"
                  v-model="selected"
                  :search="search"
                  :loading="TableLoadingFlag"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.NewZoneId="{ item }">
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="ZoneCodeOptionsLoading"
                      :items="ZoneCodeOptions"
                      :rules="ZoneCodeRules"
                      v-model="item.NewZoneId"
                      dense
                      clearable
                      required
                      outlined
                    ></v-select>
                  </template>
                  <template v-slot:item.NewRegionId="{ item }">
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="RegionCodeOptionsLoading"
                      :items="RegionCodeOptions"
                      :rules="RegionCodeRules"
                      v-model="item.NewRegionId"
                      dense
                      clearable
                      required
                      outlined
                    ></v-select>
                  </template>
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.ProfilePic="{ item }">
                    <img width="100" height="100" :src="item.ProfilePic" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      ProgressLoadingFlag: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      DownloadFlag: 0,
      ExcelFileName: "",
      excel_fields: {},

      record: {},
      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      YearName: "",

      RegionCodeRules: [(v) => !!v || "Region is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      TableTypeRules: [],
      TableType: "",
      TableTypeOptions: [],
      TableTypeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,
    };
  },
  mounted() {},
  watch: {
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
  },
  methods: {
    resetForm() {
      this.$refs.form1.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form1.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getZoneCodeOptions();
      this.getTableTypeOptions();
      this.getRegionCodeOptions();
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.RegionCodeOptionsLoading = true;
      var selectbox1_source = "RegionCode";
      var selectbox1_destination = "RegionCodeOptions";
      var selectbox1_url = "api/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableTypeOptions() {
      console.log("getTableTypeOptions called");
      var selectbox1_source = "TableType";
      var selectbox1_destination = "TableTypeOptions";
      var selectbox1_url = "api/jcom/table-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.ExcelFileFlag = false;
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/table/lists";
        var upload = {
          UserInterface: 3,
          Zone: this.ZoneCode,
          TableType: this.TableType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            records = response.data.records;
            console.log({ records });

            thisIns.tableColumns1 = records.TableHeader;
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              var TableData = records.TableData;
              console.log({ TableData });
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    changeZoneConfirmAlert(tr) {
      console.log("changeZoneConfirmAlert called");
      var message = "";

      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var temp_zone_code = tr.NewZoneId;

        if (temp_zone_code > 0) {
          console.log("temp_zone_code=" + temp_zone_code);
          var idx = this.ZoneCodeOptions.map((e) => e.value).indexOf(
            temp_zone_code
          );
          console.log("idx=" + idx);
          var newZonename = "";

          if (idx >= 0) {
            var tr1 = this.ZoneCodeOptions[idx];
            console.log({ tr1 });
            newZonename = tr1.text;
          }

          var htmlTxt = "";
          htmlTxt += "<br/> Zone Name <b> " + tr.ZoneName + "</b>";
          htmlTxt += "<br/> Lom Name <b> " + tr.JcomTableName + "</b>";
          htmlTxt += "<br/> New Zone name <b> " + newZonename + "</b>";

          Swal.fire({
            title: "Do you want to continue?",
            text: "Please verify all the information before proceeding.",
            icon: "warning",
            html: htmlTxt,
            showCancelButton: true,
            confirmButtonText: `Continue`,
            confirmButtonColor: "#d33",
            cancelButtonText: `Cancel`,
            cancelButtonColor: "#3085d6",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.changeZoneCode(tr);
            } else {
              this.sweetAlert("error", "You cancelled the process", true);
            }
          });
        } else {
          if (temp_zone_code <= 0 || temp_zone_code == "") {
            message += "New Zone Code should not be embty. ";
          }
          this.sweetAlert("error", message, false);
        }
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to change Zone. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    changeZoneCode(tr) {
      console.log("changeZoneCode is called");

      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var JcomTable = tr.JcomTableId;
        var NewZoneCode = tr.NewZoneId;
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/table/jcom-table-zone-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          JcomTable: JcomTable,
          NewZone: NewZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to change Zone. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var validate2 = this.$refs.form2.validate();
      console.log("validate2=" + validate2);

      // this.rows = [];

      if (validate1 && validate2) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/table/jcom-table-region-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          Zone: this.ZoneCode,
          JcomTables: this.tableData1,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Year should not be empty. ";
        }
        if (!validate2) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>